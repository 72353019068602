<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Ticketing from "@/services/Ticketing";
import ECharts from "vue-echarts";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/bar";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/grid";
import ColumnVisibilityV2 from "@/components/ColumnVisibilityV2";
import countTo from "vue-count-to";
import modalAssignSeasonTicket from "@/components/modals/ticketing/modalAssignSeasonTicket";
import modalManageSeasonTicketTags from "@/components/modals/ticketing/modalManageSeasonTicketTags";
import _ from "underscore";

export default {
  components: {
    Layout,
    PageHeader,
    "v-chart": ECharts,
    ColumnVisibilityV2,
    countTo,
    modalAssignSeasonTicket,
    modalManageSeasonTicketTags,
  },
  page: {
    title: "Ticketing",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      error: null,
      tableData: [],
      title: "Season Tickets",
      items: [
        {
          text: "Ticketing",
        },
        {
          text: "Season Tickets",
          active: true,
        },
      ],
      filterInput: {
        season: "",
      },
      seasons: [],
      transactionData: [],
      subscriptionData: [],
      transactionId: "",
      totalPages: 0,
      totalItems: 0,
      totalUnfilteredItems: 0,
      currentPage: 1,
      perPage: 10,
      filter: "",
      sortBy: "creation_date",
      sortDesc: true,
      showLoader: false,
      showLoader_c1: false,
      showLoader_c2: false,
      showLoader_c3: false,
      showLoader_c4: false,
      showLoader_c5: false,
      showLoader_c6: false,
      showLoader_c7: false,
      subscriptionPricing: null,
      subscriptionArea: null,
      subscriptionValidCards: 0,
      subscriptionUnassignedCards: 0,
      subscriptionOtherCards: 0,
      order_line_id: "",
      serieName: "",
      fields: [],
      cashless: "",
    };
  },
  async mounted() {
    this.dashboardDefinitions("season_tickets", "cashless");
    this.dashboardDefinitions("season_tickets", "columns"); //get columns from db
    this.getSeasonSchedule();
  },

  async created() {},
  watch: {
    filter: {
      handler: _.debounce(function() {
        // When the search input changes, load data from the backend
        this.currentPage = 1;
        this.getSubscriptionMasterData();
      }, 1500),
      deep: true, // Watch for changes inside the filter object
    },

    // Watch for changes in the season filter
    "filterInput.season": {
      handler: _.debounce(function() {
        // When the season filter changes, load data from the backend
        this.totalUnfilteredItems = 0;
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
        this.getSeasonStatistics();
      }, 100),
    },
  },
  methods: {
    async exportToExcel() {
      try {
        this.exportingData = true;
        let filters = this.getFilters();
        let exportFilters = this.setExportFilters(filters);

        const response = await Ticketing.getSubscriptionMasterData(
          exportFilters
        );
        const responseData = response.data.data;

        if (responseData.length > 0) {
          const ExcelJS = require("exceljs");
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("SeasonTicketData");

          const columnHeaders = [
            { key: "order_id", header: "Order ID", width: 15 },
            { key: "order_line_id", header: "Ticket ID", width: 15 },
            {
              key: "external_customer_id",
              header: "External Customer Id",
              width: 15,
            },
            {
              key: "external_ticket_customer_id",
              header: "External Ticket Customer Id",
              width: 15,
            },
            { key: "barcode", header: "Barcode", width: 15 },
            { key: "series_name", header: "Series Name", width: 15 },
            { key: "customer_name", header: "Customer Name", width: 30 },
            { key: "email", header: "Email", width: 30 },
            { key: "card_status", header: "Card Status", width: 30 },
            { key: "card_serial", header: "Card Serial", width: 30 },
            { key: "area_name", header: "Area", width: 30 },
            { key: "row_name", header: "Row", width: 30 },
            { key: "seat_name", header: "Seat", width: 30 },
            { key: "tags", header: "Tags", width: 30 },
            { key: "status", header: "Status", width: 30 },
            { key: "tags", header: "Tags", width: 30 },
            { key: "creation_date", header: "Creation Date", width: 30 },
          ];

          worksheet.addRow(columnHeaders.map((column) => column.header));
          worksheet.getRow(1).font = { bold: true };

          columnHeaders.forEach((column, index) => {
            const cell = worksheet.getCell(
              String.fromCharCode(65 + index) + "1"
            );
            cell.width = column.width;
          });

          responseData.forEach((item) => {
            // Create a new object with the combined customer name
            const customerName = `${item.first_name} ${item.last_name}`;
            const status = item.status == 1 ? "valid" : "removed"; // Check status value
            const rowData = columnHeaders.map((column) => {
              if (column.key === "customer_name") {
                return customerName;
              }
              if (column.key === "status") {
                return status; // Use the 'status' variable
              }
              return item[column.key];
            });
            worksheet.addRow(rowData);
          });

          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "SeasonTicketData.xlsx";
          link.click();
        } else {
          alert("No data available to export.");
        }
      } catch (error) {
        alert("An error occurred while fetching data from the API."); // Display an error message
      } finally {
        this.exportingData = false;
      }
    },

    async getSeasonSchedule() {
      try {
        this.showLoader = true;
        await Ticketing.getSeasonProducts("active=1&type[]=season")
          .then((response) => {
            this.seasons = response.data.data;
            if (this.seasons.length) {
              this.filterInput.season = this.seasons[0].product_id;
              this.getSubscriptionMasterData(true);
            }
          })
          .catch((error) => {
            this.error = error.response.data.error
              ? error.response.data.error
              : "";
            this.failedmsg("Can not get seasons");
          })
          .finally(() => {
            this.showLoader = false;
          });
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.failedmsg("Can not get seasons");
      }
    },

    async getSeasonStatistics() {
      if (this.filterInput.season == "") {
        return false;
      }

      try {
        this.showLoader_c1 = this.showLoader_c2 = this.showLoader_c4 = this.showLoader_c5 = this.showLoader_c6 = true;
        await this.getSubscriptionPricingData();
        await this.getSubscriptionAreaData();
        await this.getSubscriptionValidCards();
        await this.getSubscriptionUnassignedCards();
        await this.getSubscriptionOtherCards();
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
      } finally {
        this.showLoader_c1 = this.showLoader_c2 = this.showLoader_c3 = this.showLoader_c4 = this.showLoader_c5 = this.showLoader_c6 = false;
      }
    },

    async getSubscriptionMasterData(firstRun = false) {
      if (this.filterInput.season == "") {
        this.showLoader_c7 = false;
        this.showLoader_c3 = false;
        return false;
      }

      const selectedSeasonId = this.filterInput.season;
      const selectedSeason = this.seasons.find(
        (s) => s.product_id === selectedSeasonId
      );
      if (selectedSeason) {
        //this.serieName = selectedSeason.serie_name;
        this.serieName = selectedSeason.name;
      }

      try {
        this.showLoader_c7 = true;
        var filters = this.getFilters();
        let paginationFilters = this.setPaginationFilters(filters);
        const response = await Ticketing.getSubscriptionMasterData(
          paginationFilters
        );
        this.tableData = response.data.data;
        if (firstRun) {
          this.totalUnfilteredItems = response.data.total_items;
        }
        this.totalItems = response.data.total_items;
        this.totalPages = response.data.total_pages;
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.tableData = [];
      } finally {
        this.showLoader_c3 = false;
        this.showLoader_c7 = false;
      }
    },

    async getSubscriptionPricingData() {
      var filters = this.getFilters();
      const response = await Ticketing.getSubscriptionPricingData(filters);

      if (response.data.data) {
        this.subscriptionPricing = response.data.data;
        const keys = this.subscriptionPricing.map((item) => item.type_name);
        var arrValues = [];

        this.subscriptionPricing.forEach((key) => {
          arrValues.push({
            value: key.sum,
            name: key.type_name,
          });
        });

        this.subscriptionPricing = {
          visualMap: {
            show: false,
          },
          tooltip: {
            trigger: "item",
            //  formatter: '{a} <br/>{b} : {c} ({d}%)',
            formatter: "",
          },
          legend: {
            orient: "vertical",
            left: "left",
            data: keys,
            textStyle: {
              color: "#999",
              fontSize: 10,
              fontWeight: "bold",
            },
          },
          color: ["#f46a6a", "#34c38f", "#50a5f1", "#f1b44c", "#556ee6"],
          series: [
            {
              name: "Subscription Pricing",
              type: "pie",
              radius: "70%",
              data: arrValues,
              center: ["50%", "50%"],
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          ],
          fontSize: 12,
        };
      } else {
        this.subscriptionPricing = null;
      }
    },

    async getSubscriptionAreaData() {
      var filters = this.getFilters();
      const response = await Ticketing.getSubscriptionAreaData(filters);

      if (response.data.data) {
        var subscriptionAreaData = response.data.data;
        const keys = subscriptionAreaData.map((item) => item.area_name);
        const values = subscriptionAreaData.map((item) => item.vTotal);

        this.subscriptionArea = {
          tooltip: {
            trigger: "item",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: keys,
            axisLabel: {
              interval: 0,
              align: "center",
              rotate: 30,
              margin: 20,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 20,
            },
          },
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              type: "bar",
              data: values.map((value, index) => ({
                value,
                itemStyle: {
                  color: [
                    "#5470c6",
                    "#91cc75",
                    "#fac858",
                    "#ee6666",
                    "#73c0de",
                    "#3ba272",
                    "#fc8452",
                    "#9a60b4",
                    "#ea7ccc",
                  ][index % 9],
                },
              })),
            },
          ],
        };
      } else {
        this.subscriptionArea = null;
      }
    },

    async getSubscriptionValidCards() {
      var filters = this.getFilters();
      const response = await Ticketing.getSubscriptionValidCards(filters);
      if (response.data) {
        this.subscriptionValidCards = response.data.data;
      }
    },

    async getSubscriptionUnassignedCards() {
      var filters = this.getFilters();
      const response = await Ticketing.getSubscriptionUnassignedCards(filters);
      if (response.data) {
        this.subscriptionUnassignedCards = response.data.data;
      }
    },

    async getSubscriptionOtherCards() {
      var filters = this.getFilters();
      const response = await Ticketing.getSubscriptionOtherCards(filters);
      if (response.data) {
        this.subscriptionOtherCards = response.data.data;
      }
    },

    modalAssignSeasonTicket(data) {
      this.$bvModal.show("assign_card");
      this.subscriptionData = data;
    },

    modalManageSeasonTicketTags(order_line_id) {
      this.$bvModal.show("manage_season_ticket_attributes");
      this.order_line_id = order_line_id;
    },

    getFilters() {
      var filter_string = "";
      if (this.filterInput.season) {
        filter_string += this.filterInput.season
          ? "&serie_id=" + this.filterInput.season
          : "";
      }

      return (filter_string = filter_string.substring(1));
    },

    onDropdownHide(bvEvent) {
      bvEvent.preventDefault();
    },
    handlePaginationChange() {
      this.getSubscriptionMasterData();
    },

    statusType(status) {
      if (Number.isInteger(status)) {
        return status == 1 ? "valid" : "removed";
      } else {
        return status;
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12" v-if="!showLoader">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12  col-md-4 mx-auto">
                <b-form-group
                  label="Select Season"
                  label-for="season"
                  class="mb-3 text-center"
                >
                  <select
                    v-model="filterInput.season"
                    class="form-select"
                    id="season"
                    @change="getSubscriptionMasterData(true)"
                  >
                    <option
                      v-for="s in seasons"
                      :key="s.product_id"
                      :value="s.product_id"
                      >{{ s.name }}</option
                    >
                  </select>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="text-center">Season Statistics</h4>
            <h3 class="text-center text-muted">{{ serieName }}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="custom-accordion">
                <a
                  class="text-body fw-semibold pb-2 d-block"
                  data-toggle="collapse"
                  href="javascript: void(0);"
                  role="button"
                  aria-expanded="false"
                  v-b-toggle.numberCharts-collapse
                >
                  <i
                    class="mdi mdi-chevron-up accor-down-icon text-primary me-1"
                  ></i
                  >Numbers and Charts
                </a>
                <b-collapse visible id="numberCharts-collapse">
                  <div class="row mb-5 mt-5 mb-5 d-flex justify-content-around">
                    <div class="col-md-3">
                      <div class="card bg-gradient-light h-100">
                        <div class="card-body d-grid">
                          <div>
                            <h5 class="text-muted mb-0 text-center">
                              Total Subscriptions
                            </h5>
                          </div>
                          <h4 class="mt-3 align-self-end text-center">
                            <span data-plugin="counterup" v-if="!showLoader_c3">
                              <countTo
                                :startVal="0"
                                :endVal="totalUnfilteredItems"
                                :duration="2000"
                              ></countTo>
                            </span>
                            <div
                              class="container-fluid d-flex justify-content-center align-items-center h-50"
                              v-else
                            >
                              <b-spinner large></b-spinner>
                            </div>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div v-if="cashless" class="col-md-3">
                      <div class="card bg-gradient-light h-100">
                        <div class="card-body d-grid">
                          <div>
                            <h5 class="text-muted mb-0 text-center">
                              Subscriptions with Assigned Valid Cards
                            </h5>
                          </div>
                          <h4 class="mt-3 align-self-end text-center">
                            <span data-plugin="counterup" v-if="!showLoader_c4">
                              <countTo
                                :startVal="0"
                                :endVal="subscriptionValidCards"
                                :duration="2000"
                              ></countTo>
                            </span>
                            <div
                              class="container-fluid d-flex justify-content-center align-items-center h-50"
                              v-else
                            >
                              <b-spinner large></b-spinner>
                            </div>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div v-if="cashless" class="col-md-3">
                      <div class="card bg-gradient-light h-100">
                        <div class="card-body d-grid">
                          <div>
                            <h5 class="text-muted mb-0 text-center">
                              Subscriptions with Unasssigned Cards
                            </h5>
                          </div>
                          <h4 class="mt-3 align-self-end text-center">
                            <span data-plugin="counterup" v-if="!showLoader_c5">
                              <countTo
                                :startVal="0"
                                :endVal="subscriptionUnassignedCards"
                                :duration="2000"
                              ></countTo>
                            </span>
                            <div
                              class="container-fluid d-flex justify-content-center align-items-center h-50"
                              v-else
                            >
                              <b-spinner large></b-spinner>
                            </div>
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div v-if="cashless" class="col-md-3">
                      <div class="card bg-gradient-light h-100">
                        <div class="card-body d-grid">
                          <div>
                            <h5 class="text-muted mb-0 text-center">
                              Subscriptions with non Valid Cards
                            </h5>
                          </div>
                          <h4 class="mt-3 align-self-end text-center">
                            <span data-plugin="counterup" v-if="!showLoader_c6">
                              <countTo
                                :startVal="0"
                                :endVal="subscriptionOtherCards"
                                :duration="2000"
                              ></countTo>
                            </span>
                            <div
                              class="container-fluid d-flex justify-content-center align-items-center h-50"
                              v-else
                            >
                              <b-spinner large></b-spinner>
                            </div>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-5">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <h5>Subscription Pricing</h5>
                      </div>
                      <v-chart
                        class="w-100"
                        :options="subscriptionPricing"
                        autoresize
                        v-if="!showLoader_c1"
                      />
                      <div
                        class="container-fluid d-flex justify-content-center align-items-center h-50"
                        v-else
                      >
                        <b-spinner large></b-spinner>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <h5>Subscription Area</h5>
                      </div>
                      <v-chart
                        class="w-100"
                        :options="subscriptionArea"
                        autoresize
                        v-if="!showLoader_c2"
                      />
                      <div
                        class="container-fluid d-flex justify-content-center align-items-center h-50"
                        v-else
                      >
                        <b-spinner large></b-spinner>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="col-md-6">
              <ColumnVisibilityV2
                class="me-2"
                :fields="fields"
                :visFields="visibleFields"
              ></ColumnVisibilityV2>
              <b-button
                variant="success"
                title="Export Data"
                @click="exportToExcel()"
                :disabled="exportingData"
                >Export Data <b-spinner v-show="exportingData" small></b-spinner
              ></b-button>
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <v-app>
                <v-data-table
                  :headers="visibleFields"
                  :items="tableData"
                  :search="filter"
                  :items-per-page="perPage"
                  :page="currentPage"
                  :server-items-length="totalItems"
                  :hide-default-footer="true"
                  @update:page="onPageChange"
                  @update:items-per-page="onItemsPerPageChange"
                  @update:sort-desc="updateSortDesc"
                  @update:sort-by="updateSortBy"
                >
                  <template v-slot:item.first_name="{ item }">
                    <span v-if="item.first_name != null">
                      {{ item.first_name + " " }}
                    </span>
                    <span v-if="item.last_name != null">
                      {{ item.last_name }}
                    </span>
                  </template>

                  <template v-slot:item.email="{ item }">
                    <span v-if="!item.user_id">{{ item.email }}</span>
                    <router-link
                      v-else
                      title="View Customer"
                      :to="{ path: `/customers/profile/${item.user_id}` }"
                    >
                      {{ item.email }}
                    </router-link>
                  </template>

                  <template v-slot:item.card_status="{ item }">
                    <div
                      class="font-size-16"
                      v-html="format_status(item.card_status)"
                    ></div>
                  </template>

                  <template v-slot:item.card_serial="{ item }">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <router-link
                          :to="{
                            path: `/cashless/card-details/${item.card_serial}`,
                          }"
                          title="View Card Data"
                        >
                          {{ item.card_serial }}
                        </router-link>
                      </li>
                    </ul>
                  </template>

                  <template v-slot:item.status="{ item }">
                    <div
                      class="font-size-16"
                      v-html="format_status(statusType(item.status))"
                    ></div>
                  </template>

                  <template v-slot:item.tags="{ item }">
                    <div v-for="tag in item.tags.split(',')" :key="tag">
                      <span class="badge bg-primary font-size-14 mb-1">{{
                        tag
                      }}</span>
                    </div>
                  </template>

                  <template v-slot:item.action="{ item }">
                    <b-dropdown variant="primary" size="sm" class="dropleft">
                      <template v-slot:button-content>
                        Actions
                        <i class="mdi mdi-chevron-down"></i>
                      </template>
                      <b-dropdown-item
                        v-if="cashless == true"
                        @click="modalAssignSeasonTicket(item)"
                      >
                        Assign to card
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="modalManageSeasonTicketTags(item.order_line_id)"
                      >
                        Manage Tags
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>

                  <template v-slot:footer>
                    <v-row v-if="showLoader_c7" class="mx-4">
                      <v-col class="text-center">
                        <v-progress-linear
                          indeterminate
                          color="primary"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-pagination
                          v-model="currentPage"
                          :length="totalPages"
                          :total-visible="6"
                          @input="onPageChange"
                          circle
                        ></v-pagination>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-row>
                      <v-col md="6" sm="12">
                        <v-data-footer
                          :pagination="pagination"
                          :options="options"
                          @update:options="updateOptions"
                          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                          :items-per-page-options="itemsPerPageOpts"
                        />
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field
                          v-model="filter"
                          :append-icon="filter ? 'mdi-close' : 'mdi-magnify'"
                          @click:append="clearSearch"
                          label="Search"
                          single-line
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-app>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-body d-flex align-items-center justify-content-center"
        v-else
      >
        <b-spinner large></b-spinner>
      </div>
    </div>

    <!-- MODALS -->
    <modalAssignSeasonTicket
      :subscriptionData="subscriptionData"
    ></modalAssignSeasonTicket>
    <modalManageSeasonTicketTags
      :order_line_id="order_line_id"
      @onRefresh="getSeasonSchedule()"
    ></modalManageSeasonTicketTags>
    <!-- /MODALS -->
  </Layout>
</template>
